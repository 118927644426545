/* open-sans-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("/static/fonts/open-sans-v40-latin-300.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-300italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 300;
  src: url("/static/fonts/open-sans-v40-latin-300italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/static/fonts/open-sans-v40-latin-regular.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url("/static/fonts/open-sans-v40-latin-italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 500;
  src: url("/static/fonts/open-sans-v40-latin-500.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-500italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 500;
  src: url("/static/fonts/open-sans-v40-latin-500italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-600 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("/static/fonts/open-sans-v40-latin-600.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-600italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 600;
  src: url("/static/fonts/open-sans-v40-latin-600italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-700 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/static/fonts/open-sans-v40-latin-700.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

/* open-sans-700italic - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 700;
  src: url("/static/fonts/open-sans-v40-latin-700italic.woff2") format("woff2"); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
}

.site-title {
  font-weight: 600;
}

ul {
  margin-left: 0;
  list-style-type: none;
}

#tags li {
  display: inline-block;
  margin: 0.25em 0.25em 0.25em 0;

  a {
    font-size: 14px;
    text-decoration: underline;
  }
}

#post-list li {
  margin: 0.5em 0;
}

.home img {
  max-width: 200px;
  border-radius: 50%;
  float: left;
  margin: 1em;
}

.black {
  color: black;
}

.site-footer .wrapper {
  display: flex;
  justify-content: center;

  p {
    display: inline-block;
    margin: 0 0.5em;
  }
}
